import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { arraybufferToJSON, openFile } from '@app/utils/constants'
import { awbCreators, awbTypes } from './redux'
import _ from 'lodash'
import { baseCreators } from '@app/containers/Base/redux'
import commons from '@app/constants/commons'
import { dpApi } from '@app/services/api'
import { selectAirwayBillIds } from './selectors'
import { selectCountry } from '@app/containers/Base/selectors'
import { getErrorMessage } from '@app/utils/commonUtils'

const { showError } = baseCreators

export function * printAirwayBill ({ index, paperSize }) {
  const country = yield select(selectCountry())
  const trackingIds = yield select(selectAirwayBillIds(index))
  const payload = {
    tracking_ids: trackingIds,
    hide_shipper_details: 0,
    size: paperSize || commons.AWB_SIZE_A4
  }

  const resp = yield call(dpApi.generateAwb, country, payload)
  const data = _.get(resp, 'data')

  if (!resp.ok) {
    const { error } = arraybufferToJSON(data)
    yield put(showError(getErrorMessage(resp, error)))
    yield put(awbCreators.failPrintAwb(index))
    return
  }

  // eslint-disable-next-line
  const blob = new Blob([data], { type: 'application/pdf' })
  openFile(blob)

  yield put(awbCreators.successPrintAwb(index))
}

export default function * awbData () {
  yield all([takeEvery(awbTypes.REQUEST_PRINT_AWB, printAirwayBill)])
}
