// load the favicon, the manifest.notajson file and the .htaccess file
/* eslint-disable import/no-webpack-loader-syntax */
import '!file-loader?name=[name].[ext]!./images/favicon.ico'
import '!file-loader?name=[name].[ext]!./images/favicon-cd.png'
import '!file-loader?name=[name].[ext]!./images/icon-72x72.png'
import '!file-loader?name=[name].[ext]!./images/icon-96x96.png'
import '!file-loader?name=[name].[ext]!./images/icon-128x128.png'
import '!file-loader?name=[name].[ext]!./images/icon-120x120.png'
import '!file-loader?name=[name].[ext]!./images/icon-144x144.png'
import '!file-loader?name=[name].[ext]!./images/icon-150x150.png'
import '!file-loader?name=[name].[ext]!./images/icon-152x152.png'
import '!file-loader?name=[name].[ext]!./images/icon-167x167.png'
import '!file-loader?name=[name].[ext]!./images/icon-180x180.png'
import '!file-loader?name=[name].[ext]!./images/icon-192x192.png'
import '!file-loader?name=[name].[ext]!./images/icon-384x384.png'
import '!file-loader?name=[name].json!./manifest.notajson'
import '!file-loader?name=[name].json!./version.notajson'
import '!file-loader?name=[name].json!./assetlinks.notajson'
import '!file-loader?name=[name].[ext]!./__mocks__/msw/mockServiceWorker.js'
import 'file-loader?name=[name].[ext]!./.htaccess'
import '@babel/polyfill' // needed for redux-saga es6 generator support
import 'core-js/fn/array/flat-map'
import 'sanitize.css/sanitize.css'
import '@nv/react-akira/dist/index.css'
import * as Sentry from '@sentry/react'
import { datadogRum } from '@datadog/browser-rum'
import { MIXPANEL_DEBUG_MODE, MIXPANEL_PROJECT_TOKEN } from './utils/mixpanelUtils'
import { DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN, DATADOG_SITE, DATADOG_SERVICE } from './utils/datadogUtils'
import React, { Fragment } from 'react'
import { loadState, saveState } from './LocalStorage'
import App from '@app/containers/Base'
import { AppGlobalStyles } from './global-styles'
import Config from 'configs'
import { ConnectedRouter } from 'react-router-redux'
import FontFaceObserver from 'fontfaceobserver'
import { Integrations } from '@sentry/tracing'
import LanguageProvider from '@app/containers/LanguageProvider'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import { SizeProvider } from '@nv/rc/Components'
import { ThemeProvider } from 'styled-components'
import commons from '@app/constants/commons'
import configureStore from './configureStore'
import createHistory from 'history/createBrowserHistory'
import { fetchVersionApi } from '@app/services/api'
import { getTheme } from '@app/themes'
import mixpanel from 'mixpanel-browser'
import { sizes } from '@app/themes/media'
import { translationMessages } from './i18n'
import { worker } from '@app/__mocks__/msw/browser'

if (process.env.NODE_ENV === 'test') {
  worker.start({
    onUnhandledRequest: 'error'
  })
}

if (Config.SENTRY_DSN) {
  Sentry.init({
    dsn: Config.SENTRY_DSN,
    environment: Config.ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    // https://docs.sentry.io/platforms/javascript/configuration/sampling/#sampling-transaction-events
    tracesSampleRate: 0.2
  })
}

const LatoObserver = new FontFaceObserver('Lato', {})

LatoObserver.load().then(
  () => {
    document.body.classList.add('fontLoaded')
  },
  () => {
    document.body.classList.remove('fontLoaded')
  }
)

const persistedStore = loadState()
const history = createHistory()
const store = configureStore(persistedStore, history)
store.subscribe(() => {
  saveState(store.getState())
})
const MOUNT_NODE = document.getElementById('app')

const fetchVersionData = () => {
  let versionData = null
  setInterval(() => {
    if (!window.localStorage.getItem('isOutdated')) {
      fetchVersionApi().then(data => {
        versionData = data
      })
      if (window.localStorage.getItem('localVersion') !== versionData) {
        window.localStorage.setItem('isOutdated', true)
      }
    }
  }, commons.FIVE_MINUTES)
}

// suppress expected errors & warnings since they tend to take up space & make it hard to look for actual errors &
// warnings during development
if (Config.ENVIRONMENT === 'dev') {
  // eslint-disable-next-line no-console
  const consoleError = console.error

  // eslint-disable-next-line no-console
  console.error = (...params) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Console/error
    if (params && typeof params[0] === 'string') {
      const msg = params[0]
      if (msg.includes('Warning: React does not recognize the `%s` prop on a DOM element.')) return
      if (msg.includes('Warning: Received `%s` for a non-boolean attribute `%s`.')) return
      if (msg.includes('Warning: Unknown event handler property `%s`. It will be ignored.')) return
      if (msg.includes('Warning: Failed prop type:')) return
    }
    consoleError(...params)
  }
}

// https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelset_config
if (MIXPANEL_PROJECT_TOKEN) {
  mixpanel.init(MIXPANEL_PROJECT_TOKEN, {
    debug: MIXPANEL_DEBUG_MODE,
    secure_cookie: true // mixpanel cookies will only be transmitted over https
  })
}

// https://docs.datadoghq.com/fr/real_user_monitoring/browser/setup/#npm
if (Config.ENVIRONMENT?.toLowerCase() === 'prod' && DATADOG_CLIENT_TOKEN) {
  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    env: Config.ENVIRONMENT,
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  })
}

const render = messages => {
  fetchVersionData()
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <SizeProvider mediaSizes={sizes}>
            <ThemeProvider theme={getTheme()}>
              <Fragment>
                <App />
                <AppGlobalStyles />
              </Fragment>
            </ThemeProvider>
          </SizeProvider>
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE
  )
}

if (module.hot) {
  // hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies, they
  // have to be constants at compile time
  module.hot.accept(['./i18n', 'containers/Base'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render(translationMessages)
  })
}

// chunked polyfill for browsers without intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'))
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/th.js'),
        import('intl/locale-data/jsonp/id.js'),
        import('intl/locale-data/jsonp/my.js')
      ]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err
    })
} else {
  render(translationMessages)
}
