// @flow

import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import commons from '@app/constants/commons'
import { push } from 'react-router-redux'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import moment from 'moment'
import _ from 'lodash'
import type { Saga } from 'redux-saga'
import { getErrorMessage } from '@app/utils/commonUtils'
import { ASYNC_REQUEST_STATUS, ASYNC_SUB_REQUEST_STATUS } from '@app/constants/asyncRequest'
import { selectCountry, selectDpId, selectDpInfo, selectLocation } from '@app/containers/Base/selectors'
import {
  getBillingZoneApi,
  createPackOrderApi,
  createPostOrderApi,
  createReceiptIdApi,
  createSendOrderApi,
  dpApi,
  getOrderDetailsApi,
  getShipperSetupStatusApi,
  scanOrderApi
} from '@app/services/api'
import { createPostOrderAsyncApi } from '@app/services/api/createOrder/createPostOrderApi'
import { postShipperPriceAsyncApiWithGlobalShipperId } from '@app/services/api/postShipperPriceAsync/api'
import { getAsyncRequestApi } from '@app/services/api/getAsyncRequest/api'
import { LODGE_IN_STATUS_REQUEST } from '@app/containers/BulkUpload/constants'
import { showNotification } from '@app/utils/notify'
import { trackCustomEvent, trackPostOrderStatusEvent } from '@app/utils/trackCustomEvent'
import type { ShipperSetupPayload } from '@app/types/ShipperSetupPayload'
import {
  ERROR_CODES,
  NOTIFICATIONS,
  OC_STATUS,
  POST_FORM_FIELDS,
  POST_PRICING_FIELDS,
  ROUTES,
  SHIPPER_SEARCH_FIELDS,
  EMPTY_STAMP_ID,
  arraybufferToJSON,
  getSessionFromOrder,
  isEqual,
  openFile
} from '@app/utils/constants'
import {
  constructPostOrderRequestPayload,
  constructPostOrderAsyncRequestPayload,
  enrichOrderWithPostOrderResponse,
  getOCManualPackParcelPayload,
  getOCPostParcelPayload,
  getOCSenderPayload,
  getReturnOrderPayload,
  getReturnType,
  isValidOrderTypeForCountry
} from '@app/utils/OrderUtils'
import { ENTRY_POINT_ORDER_CREATION, mixpanelTrackSearchedForShipper } from '@app/utils/mixpanelUtils'
import { baseCreators } from '@app/containers/Base/redux'
import { pollLodgeIn } from '@app/sagas/BulkUpload'

import { firstMileCreators, firstMileTypes } from './redux'
import { locationsCreators } from '@app/containers/LocationsProvider/redux'
import {
  makeSelectFirstMileCurrentSession,
  makeSelectFirstMileOrders,
  makeSelectFirstMileReceiptId,
  makeSelectFirstMileSenderDetails
} from './selectors'
import { calculatePrice, isShipperSourcePudoOrNullOrEmpty } from './utils'

import { reservations } from '@app/constants'
import { RETRY_SHIPPER_CREATION_MESSAGE, PROCESSING_A6_RECEIPT_ERROR_MESSAGE } from '@app/containers/FirstMile/constants'

import type { Order } from '@app/types/Order'
import type { SenderDetailsResponse as SenderDetails } from '@app/types/SenderDetailsResponse'
import type { DpInfo } from '@app/types/DpInfo'
import type { PostOrderRequestPayload } from '@app/types/PostOrderRequestPayload'
import type { PostOrderResponse } from '@app/types/PostOrderResponse'
import type { Response } from '@app/types/Response'
import type { ScanOrderData } from '@app/types/ScanOrderData'
import type { ReceiptIdData } from '@app/types/ReceiptIdData'
import type { SendOrderFailureResponse } from '@app/types/SendOrderResponse'
import type { ErrorData } from '@app/types/ErrorData'
import {
  mixpanelTrackCompletedParcelLodgeIn,
  mixpanelTrackCompletedDropOffProcess,
  mixpanelTrackCompletedShipperRegistration
} from '@app/utils/mixpanelUtils'
import {
  POST_PRICING_FIELDS_FOR_QUICK_ADDRESS
} from "../../utils/constants";

const { ORDER_TYPES } = reservations

const { showLoading, showError, showInfo } = baseCreators

const POLL_DELAY_IN_MS = 500
const MAX_POLL_COUNT = 30

export function * getOrder (action: any): Saga {
  const { id, path, onSuccess, index, onFailure } = action
  const {
    updateCurrentSession,
    successGetSendOrder,
    successGetPostParcel,
    successGetPackParcel,
    successGetReturnOrder,
    failGetOrder,
    resetSession
  } = firstMileCreators
  const country = yield select(selectCountry())
  const orders = yield select(makeSelectFirstMileOrders())
  const dpInfo = yield select(selectDpInfo())

  // Duplicate Check
  const currentSession = yield select(makeSelectFirstMileCurrentSession())
  let shouldRoute = false
  let order
  switch (currentSession) {
    case ORDER_TYPES.post:
      order = _.find(_.get(orders, '[0].parcels'), o => isEqual(id, o.stampId.id))
      break
    case ORDER_TYPES.pack:
      order = _.find(_.get(orders, '[0].parcels'), o => isEqual(id, o.trackingId))
      break
    default:
      order = _.find(orders, o => isEqual(id, o.trackingId))
  }
  if (order) {
    _.isFunction(onFailure) && (yield call(onFailure, 'order_is_already_added'))
    yield put(failGetOrder())
    return
  }
  const getOrderResponse: Response<ScanOrderData> = yield call(scanOrderApi, country, id)
  if (getOrderResponse.ok) {
    const data = getOrderResponse.data.data
    const session = yield call(getSessionFromOrder, data)
    // Check current session
    if (currentSession && !isEqual(session, currentSession)) {
      _.isFunction(onFailure) && (yield call(onFailure, 'order_is_not_valid_in_the_current_session'))
      yield put(failGetOrder())
      return
    }
    if (!currentSession) yield put(updateCurrentSession(session))

    if (!isValidOrderTypeForCountry(dpInfo, session)) {
      _.isFunction(onFailure) && (yield call(onFailure, 'unsupported_order_type'))
      yield put(failGetOrder())
      yield put(resetSession())
      return
    }

    switch (session) {
      case ORDER_TYPES.send:
        yield put(successGetSendOrder(data))
        break
      case ORDER_TYPES.pack:
        yield put(successGetPackParcel(data.trackingId, index))
        shouldRoute = isEqual(path, ROUTES.scanParcel.path)
        break
      case ORDER_TYPES.post:
      case ORDER_TYPES.bulkPost:
        yield put(successGetPostParcel(data.stampId, index))
        shouldRoute = isEqual(path, ROUTES.scanParcel.path)
        break
      case ORDER_TYPES.return:
        const newData = { ...data, isConfirmed: false}
        yield put(successGetReturnOrder(newData))
        break
      default:
        yield call(showNotification, 'Unknown Session', NOTIFICATIONS.ERROR)
        return
    }
    shouldRoute && (yield put(push(ROUTES.newOrder.path)))
    onSuccess && (yield call(onSuccess, id, session))
  } else {
    const error = getOrderResponse.data.error
    const errorMessage = _.get(error, 'message')
    _.isFunction(onFailure) && (yield call(onFailure, errorMessage || 'something_went_wrong', !errorMessage))
    yield put(failGetOrder())
  }
}


export function * getOrderWithoutStampId (action: any): Saga {
  const { successGetPostParcel, updateCurrentSession } = firstMileCreators
  const stampId = EMPTY_STAMP_ID
  const index = -1

  yield put(updateCurrentSession(ORDER_TYPES.post))
  yield put(successGetPostParcel(stampId, index))
}

export function * getShippers (action: any): Saga {
  const { value, searchAll, form, messages } = action
  const { selectShipper, successGetShippers, failGetShippers, fetchShipperSetupStatus } = firstMileCreators
  const country = yield select(selectCountry())
  const getShippersResponse = yield call(dpApi.searchShipperByContact, country, value, searchAll)
  const { data, error } = getShippersResponse.data
  if (getShippersResponse.ok) {
    if (form && (_.isNull(data) || !data.length)) {
      form.setFields({
        [SHIPPER_SEARCH_FIELDS.searchText]: {
          value,
          errors: [new Error(messages.noResult)]
        }
      })
      yield put(failGetShippers())
      mixpanelTrackSearchedForShipper(ENTRY_POINT_ORDER_CREATION, false)
      return
    }
    let shippers = []
    const defaultShipper = data.find(s => s.isDefault)
    if (!searchAll && defaultShipper) {
      yield put(selectShipper(defaultShipper))
      if (isShipperSourcePudoOrNullOrEmpty(defaultShipper.source)) {
        const payload: ShipperSetupPayload = {
          global_shipper_id: defaultShipper.globalId
        }
        const asyncShipperSetupStatus = yield call(getShipperSetupStatusApi, country, defaultShipper.globalId, payload)
        const asyncShipperSetupStatusError = asyncShipperSetupStatus.data.error
        if (!asyncShipperSetupStatusError) {
          const asyncShipperSetupStatusData = asyncShipperSetupStatus.data.data
          const asyncRequestId = asyncShipperSetupStatusData.id
          let pollCount = 0
          while (pollCount < MAX_POLL_COUNT) {
            const asyncFetchShipperSetUpResponse = yield call(getAsyncRequestApi, country, asyncRequestId)
            const asyncShipperSetupResponseError = asyncFetchShipperSetUpResponse.data.error
            if (asyncShipperSetupResponseError) {
              yield put(failGetShippers())
              mixpanelTrackSearchedForShipper(ENTRY_POINT_ORDER_CREATION, false)
              return
            }
            const asyncShipperSetUpData = asyncFetchShipperSetUpResponse.data.data
            switch (asyncShipperSetUpData.status) {
              case ASYNC_REQUEST_STATUS.SUCCEEDED: {
                yield put(fetchShipperSetupStatus(!asyncFetchShipperSetUpResponse.ok))
                pollCount += MAX_POLL_COUNT
                break
              }
              case ASYNC_REQUEST_STATUS.FAILED: {
                yield put(fetchShipperSetupStatus(asyncFetchShipperSetUpResponse.ok))
                pollCount += MAX_POLL_COUNT
                break
              }
              default: {
                yield delay(POLL_DELAY_IN_MS)
                pollCount += 1
              }
            }
          }
        } else {
          yield put(failGetShippers())
          mixpanelTrackSearchedForShipper(ENTRY_POINT_ORDER_CREATION, false)
        }
      }
    } else {
      shippers = _.orderBy(data, ['isDefault'], ['desc'])
    }
    yield put(successGetShippers(shippers))
    mixpanelTrackSearchedForShipper(ENTRY_POINT_ORDER_CREATION, true)
  } else {
    if (form) {
      form.setFields({
        [SHIPPER_SEARCH_FIELDS.searchText]: {
          value,
          errors: [new Error(_.get(error, 'message'))]
        }
      })
    }
    yield put(failGetShippers())
    mixpanelTrackSearchedForShipper(ENTRY_POINT_ORDER_CREATION, false)
  }
}

export function * createShipper (action: any): Saga {
  yield put(showLoading(true))
  const { shipper } = action
  const { selectShipper, successCreateShipper, failCreateShipper, fetchShipperSetupStatus } = firstMileCreators
  let asyncFetchCreateShipperResponseData

  yield put(fetchShipperSetupStatus(false))
  const country = yield select(selectCountry())
  const shipperDetails = _.get(shipper, 'senderDetails', {})
  const asyncCreateShipperResponse = yield call(dpApi.createShipper, country, shipperDetails)

  if (asyncCreateShipperResponse.ok) {
    const asyncCreateShipperResponseData = asyncCreateShipperResponse?.data?.data
    const asyncRequestId = asyncCreateShipperResponseData?.id
    let pollCount = 0

    while (pollCount < MAX_POLL_COUNT) {
      const asyncFetchCreateShipperResponse = yield call(getAsyncRequestApi, country, asyncRequestId)
      const asyncFetchCreateShipperResponseError = asyncFetchCreateShipperResponse?.data?.error

      if (!asyncFetchCreateShipperResponseError) {
        asyncFetchCreateShipperResponseData = asyncFetchCreateShipperResponse?.data?.data

        switch (asyncFetchCreateShipperResponseData.status) {
          case ASYNC_REQUEST_STATUS.SUCCEEDED: {
            yield put(showLoading(false))
            yield put(successCreateShipper())
            pollCount += MAX_POLL_COUNT
            break
          }
          case ASYNC_REQUEST_STATUS.FAILED: {
            if (asyncFetchCreateShipperResponseData.responseMessage != null) {
              yield put(showError(JSON.parse(asyncFetchCreateShipperResponseData.responseMessage).message))
            }
            yield put(failCreateShipper())
            yield put(showLoading(false))
            return
          }
          default: {
            yield delay(POLL_DELAY_IN_MS)
            pollCount += 1
          }
        }
      }
    }
  } else {
    yield put(showError(RETRY_SHIPPER_CREATION_MESSAGE))
    yield put(failCreateShipper())
    yield put(showLoading(false))
    return
  }

  const successShipperCreationData = _.mapKeys(
    JSON.parse(asyncFetchCreateShipperResponseData?.responseMessage),
    (value, key) => _.camelCase(key)
  )
  const globalShipperId = successShipperCreationData.globalId
  const payload: ShipperSetupPayload = {
    global_shipper_id: globalShipperId
  }
  const asyncShipperSetupStatusReponse = yield call(getShipperSetupStatusApi, country, globalShipperId, payload)
  const asyncShipperSetupStatusResponseOk = asyncShipperSetupStatusReponse?.ok

  yield put(selectShipper(successShipperCreationData))

  if (asyncShipperSetupStatusResponseOk) {
    const asyncShipperSetupStatusData = asyncShipperSetupStatusReponse?.data?.data
    const asyncRequestId = asyncShipperSetupStatusData.id
    let pollCount = 0
    while (pollCount < MAX_POLL_COUNT) {
      const asyncFetchShipperSetUpResponse = yield call(getAsyncRequestApi, country, asyncRequestId)
      const asyncShipperSetupResponseError = asyncFetchShipperSetUpResponse?.data?.error
      if (asyncShipperSetupResponseError) {
        yield put(failCreateShipper())
        return
      }
      const asyncShipperSetUpData = asyncFetchShipperSetUpResponse?.data?.data
      switch (asyncShipperSetUpData.status) {
        case ASYNC_REQUEST_STATUS.SUCCEEDED: {
          yield put(fetchShipperSetupStatus(!asyncFetchShipperSetUpResponse.ok))
          // tracking only after shipper creation & pricing setup are successful
          mixpanelTrackCompletedShipperRegistration()
          pollCount += MAX_POLL_COUNT
          break
        }
        case ASYNC_REQUEST_STATUS.FAILED: {
          yield put(fetchShipperSetupStatus(asyncFetchShipperSetUpResponse.ok))
          pollCount += MAX_POLL_COUNT
          break
        }
        default: {
          yield delay(POLL_DELAY_IN_MS)
          pollCount += 1
        }
      }
    }
  }
}

export function * getShipperSetupStatus ({ legacyShipperId, globalShipperId }: any): any {
  yield put(showLoading(true))
  const { fetchShipperSetupStatus } = firstMileCreators
  const country = yield select(selectCountry())
  const shipperSetupPayload: ShipperSetupPayload = {
    global_shipper_id: globalShipperId
  }
  const asyncShipperSetupStatus = yield call(getShipperSetupStatusApi, country, globalShipperId, shipperSetupPayload)
  const asyncShipperSetupStatusError = asyncShipperSetupStatus.data.error

  if (!asyncShipperSetupStatusError) {
    const asyncShipperSetupStatusData = asyncShipperSetupStatus.data.data
    const asyncRequestId = asyncShipperSetupStatusData.id
    let pollCount = 0
    while (pollCount < MAX_POLL_COUNT) {
      const asyncFetchShipperSetUpResponse = yield call(getAsyncRequestApi, country, asyncRequestId)
      const asyncShipperSetupResponseError = asyncFetchShipperSetUpResponse.data.error
      if (asyncShipperSetupResponseError) {
        return
      }
      const asyncShipperSetUpData = asyncFetchShipperSetUpResponse.data.data
      switch (asyncShipperSetUpData.status) {
        case ASYNC_REQUEST_STATUS.SUCCEEDED: {
          yield put(fetchShipperSetupStatus(!asyncFetchShipperSetUpResponse.ok))
          yield put(showLoading(false))
          pollCount += MAX_POLL_COUNT
          break
        }
        case ASYNC_REQUEST_STATUS.FAILED: {
          yield put(fetchShipperSetupStatus(asyncFetchShipperSetUpResponse.ok))
          yield put(showLoading(false))
          pollCount += MAX_POLL_COUNT
          break
        }
        default: {
          yield delay(POLL_DELAY_IN_MS)
          pollCount += 1
        }
      }
    }
  }
}

export function * uploadImage (action: any): Saga {
  const country = yield select(selectCountry())
  const { successUploadImage, failUploadImage } = firstMileCreators
  const { file, index, afterUpload } = action
  const uploadImageResponse = yield call(dpApi.uploadImage, country, file)
  const { data, error } = uploadImageResponse.data
  if (uploadImageResponse.ok) {
    yield put(successUploadImage(data, index))
  } else {
    if (error && error.message) yield put(showError(error.message))
    yield put(failUploadImage(error))
  }
  afterUpload && (yield call(afterUpload))
}

export function * getPrice (action: any): Saga {
  const { parcel, index, shipperId, globalShipperId, isQuickAddressFeatureEnabled } = action
  const { resetPrice, successGetPrice, failGetPrice } = firstMileCreators
  const { deliveryType, weight, insuredValue, codValue, location } = parcel
  const country = yield select(selectCountry())
  const dpLocation = yield select(selectLocation())
  const insuranceFields = [POST_FORM_FIELDS.insurance, POST_FORM_FIELDS.insuredValue]
  const codFields = [POST_FORM_FIELDS.cod, POST_FORM_FIELDS.codValue]

  let requiredFields
  if (isQuickAddressFeatureEnabled) {
    // For order creation, parcel will have State/City/District and L1/L2/L3 address display values from the dropdown menus
    // For editing orders, DP service returns State/City/District and billing zone fields, but without the L1/L2/L3 address display values
    requiredFields = [...POST_PRICING_FIELDS_FOR_QUICK_ADDRESS]

  } else {
    requiredFields = [...POST_PRICING_FIELDS]
  }
  requiredFields = _.difference(requiredFields, insuranceFields)
  requiredFields = _.difference(requiredFields, codFields)
  const values = requiredFields.filter(field => _.get(parcel, field, false))

  if (!shipperId || values.length !== requiredFields.length) {
    yield put(resetPrice(index))
    return
  }

  const payload = {
    delivery_type: deliveryType,
    weight,
    insured_value: isNaN(insuredValue) ? 0 : insuredValue,
    cod_value: isNaN(codValue) ? 0 : codValue,
    from_latitude: dpLocation.latitude,
    from_longitude: dpLocation.longitude,
    to_latitude: _.get(location, 'lat'),
    to_longitude: _.get(location, 'lng'),
    size: 'S',
    order_type: 'NORMAL',
    timeslot_type: 'NONE',
    global_shipper_id: globalShipperId
  }

  // For Quick Address, use billing zone from given L1/L2/L3 addresses to fetch order prices instead of lat/lng
  // Note: To fetch billing zones, use L1/L2/L3 address display value, instead of address value
  // State/City/District are populated with L1/L2/L3 address display values respectively
  if (isQuickAddressFeatureEnabled) {
      const billingZoneRequestPayload = [
        {
          l1_name: parcel?.location?.state,
          l2_name: parcel?.location?.city,
          l3_name: parcel?.location?.district
        }
      ]

      const billingZoneResponse = yield call(getBillingZoneApi, country, billingZoneRequestPayload)
      const billingZoneInformation = _.get(billingZoneResponse, 'data')[0]
      payload.to_billing_zone = _.get(billingZoneInformation, 'data.billing_zone')
      payload.to_l1_id = _.get(billingZoneInformation, 'data.l1_id')
      payload.to_l2_id = _.get(billingZoneInformation, 'data.l2_id')
      payload.to_l3_id = _.get(billingZoneInformation, 'data.l3_id')
      payload.to_billing_zone_metadata = _.get(billingZoneInformation, 'data.metadata')
  }

  const asyncFetchPricingRegistrationResponse = yield call(postShipperPriceAsyncApiWithGlobalShipperId, country, globalShipperId, payload)

  const asyncPriceRegisterError = asyncFetchPricingRegistrationResponse.data.error
  if (!asyncPriceRegisterError) {
    const asyncPriceRegisterData = asyncFetchPricingRegistrationResponse.data.data
    const asyncRequestId = asyncPriceRegisterData.id
    let pollCount = 0
    while (true) {
      if (pollCount > MAX_POLL_COUNT) {
        yield put(failGetPrice())
        return
      }

      const asyncFetchPricingResponse = yield call(getAsyncRequestApi, country, asyncRequestId)

      const asyncPriceResultError = asyncFetchPricingResponse.data.error
      if (asyncPriceResultError) {
        yield put(showError(asyncPriceResultError.message))
        yield put(failGetPrice())
        return
      }
      const asyncPriceResultData = asyncFetchPricingResponse.data.data
      switch (asyncPriceResultData.status) {
        case ASYNC_REQUEST_STATUS.SUCCEEDED: {
          let price = calculatePrice(asyncPriceResultData.responseMessage)
          // Add billing zone information for Quick Address order creation
          price = {
            ...price,
            billing_zone: payload?.to_billing_zone,
            billing_zone_metadata: payload?.to_billing_zone_metadata,
            l1_id: payload?.to_l1_id,
            l2_id: payload?.to_l2_id,
            l3_id: payload?.to_l3_id
          }
          yield put(successGetPrice(price, index))
          return
        }
        case ASYNC_REQUEST_STATUS.FAILED: {
          yield put(showError(asyncPriceResultData.responseMessage))
          yield put(failGetPrice())
          return
        }
        default: {
          yield delay(POLL_DELAY_IN_MS)
          pollCount += 1
        }
      }
    }
  } else {
    yield put(showError(asyncPriceRegisterError.message))
    yield put(failGetPrice())
  }
}

export function * lodgeIn (action: any): Saga {
  yield put(showLoading(true))
  const { orders, lodgeInDetails, afterHandover } = action
  const { finishCreateOrder } = firstMileCreators
  const currentSession = yield select(makeSelectFirstMileCurrentSession())
  const country: string = yield select(selectCountry())
  const dpId: number = yield select(selectDpId())
  const shouldNotifyShipper = true

  let receiptId: number
  const getReceiptIdResponse: Response<ReceiptIdData> = yield call(createReceiptIdApi, country)
  if (getReceiptIdResponse.ok) {
    receiptId = getReceiptIdResponse.data.data
  } else {
    const errorMessage = _.get(getReceiptIdResponse.data.error, 'message')
    errorMessage && (yield put(showError(errorMessage)))
    yield put(push(ROUTES.sendParcel.path))
    return
  }

  let uniqueOrders = _.uniqBy(orders, order => order.trackingId)

  if (isEqual(currentSession, ORDER_TYPES.send)) {
    const payload = {
      reservations: uniqueOrders.map(order => ({
        trackingId: _.get(order, 'trackingId'),
      }))
    }

    const sendResponse: Response<any> = yield call(createSendOrderApi, country, shouldNotifyShipper, payload)

    if (sendResponse.ok && _.get(sendResponse, 'data.data.statusId')) {
      const { statusId } = sendResponse.data.data

      try {
        const lodgeInResponse: Response<any> = yield call(pollLodgeIn, country, statusId)
        if (_.get(lodgeInResponse, 'data.data.status', '') === LODGE_IN_STATUS_REQUEST.PROCESSING) {
          const info = {
            title: 'lodge_in_request_accepted',
            message: 'lodge_in_processing_message',
            redirectPage: ROUTES.history.path
          }
          yield put(showLoading(false))
          yield put(showInfo(info))
          return
        }
        const failedOrders = _.get(lodgeInResponse, 'data.data.failedOrders', [])
        const failedOrderCount = failedOrders.length

        const failedOrdersByBarcode = _.keyBy(failedOrders, 'barcode')

        uniqueOrders = uniqueOrders.map(order => {
          const foundFailedOrder = _.get(failedOrdersByBarcode, [order.trackingId])
          const status = foundFailedOrder ? OC_STATUS.failed : OC_STATUS.success
          const message = foundFailedOrder ? _.get(foundFailedOrder, 'errorMessage', 'Unexpected error') : ''

          return {
            ...order,
            oc: {
              ...order.oc,
              status,
              message
            }
          }
        })
        const successOrderCount = _.get(lodgeInResponse, 'data.data.successfulOrders', 0)
        if (lodgeInDetails?.isNewShipperDropOff) {
          const manualInputParcelsCount = lodgeInDetails.manualInputParcelsCount ?? 0
          const transactionStartTime = moment(lodgeInDetails?.transactionStartTime)
          const duration = Math.round(moment.duration(moment().diff(transactionStartTime)).asMilliseconds())
          const transactionDuration = moment.utc(duration).format("HH:mm:ss")
          mixpanelTrackCompletedDropOffProcess(lodgeInDetails.entryPoint, failedOrderCount, successOrderCount, manualInputParcelsCount, transactionDuration)
        } else {
          mixpanelTrackCompletedParcelLodgeIn(successOrderCount)
        }
        trackCustomEvent('individualsend', {
          successOrders: successOrderCount,
          failedOrders: failedOrderCount
        })
      } catch (err) {
        uniqueOrders = uniqueOrders.map(order => ({
          ...order,
          oc: {
            ...order.oc,
            status: OC_STATUS.failed,
            message: err.message || 'There was an unexpected error'
          }
        }))
        trackCustomEvent('individualsend', { successOrders: 0, failedOrders: uniqueOrders.length })
      }
    } else {
      const failureResponse: SendOrderFailureResponse = sendResponse
      uniqueOrders = uniqueOrders.map(order => ({
        ...order,
        oc: {
          ...order.oc,
          status: OC_STATUS.failed,
          message: failureResponse.data.error.message || 'There was an unexpected error'
        }
      }))
      trackCustomEvent('individualsend', { successOrders: 0, failedOrders: uniqueOrders.length })
    }
  } else {
    for (let i = 0; i < uniqueOrders.length; i++) {
      const payload = getReturnOrderPayload(uniqueOrders[i], dpId, receiptId)
      const createOrderResponse = yield call(
        dpApi.createReturnOrder,
        country,
        getReturnType(uniqueOrders[i].type),
        payload
      )
      const { error } = createOrderResponse.data
      _.set(uniqueOrders[i], 'receiptId', receiptId)
      if (createOrderResponse.ok) {
        _.set(uniqueOrders[i], 'oc.status', OC_STATUS.success)
      } else {
        _.set(uniqueOrders[i], 'oc.status', OC_STATUS.failed)
        _.set(uniqueOrders[i], 'oc.message', _.get(error, 'message', 'There was an unexpected error'))
      }
    }
  }
  yield put(finishCreateOrder(uniqueOrders))
  yield put(showLoading(false))
  afterHandover && (yield call(afterHandover))
}

export function * createPostOrder (action: any): Saga {
  yield put(showLoading(true))
  const country = yield select(selectCountry())
  const dpInfo: DpInfo = yield select(selectDpInfo())

  const isDraft: boolean = _.get(action, 'isDraft', false)

  // This is used to determine whether to save first-time consignee information
  // This feature is supported only for POST orders created via UI
  // Staging orders and POST orders uploaded via CSV are not supported, see DP-6618
  const isBulkOrderCreation = false

  // This saga only supports POST orders created via UI
  // POST orders uploaded via CSV is handled by BulkUpload reducer/saga instead
  // Quick Address feature is not supported for bulk POST order creation via CSV
  const isQuickAddressFeatureEnabled = true
  const senderDetails: SenderDetails = _.get(action, 'order.senderDetails', {})
  const orders: Array<[Order, SenderDetails]> = _.get(action, 'order.parcels', []).map((order: Order) => {
    return [order, senderDetails]
  })

  const payload: PostOrderAsyncRequestPayload = constructPostOrderAsyncRequestPayload(orders, dpInfo, country, isDraft, isBulkOrderCreation, isQuickAddressFeatureEnabled)

  const response = yield call(createPostOrderAsyncApi, country, payload)

  if (response.ok && response?.data?.data) {
    const { postOrderCreationResponse, receiptDetails  } = yield getPostOrderCreationResponse(country, response)   
    const updatedOrders: Object[] = orders.map((tuple: [Order, SenderDetails], index) => {
    const order: Order = tuple[0]
    const senderDetails: SenderDetails = tuple[1]
    const postOrderResponse: PostOrderResponse =
      _.find(postOrderCreationResponse?.data, ['data.reservation.stamp_id', order.stampId.id]) ||
      _.find(postOrderCreationResponse?.data, ['error.stamp_id', order.stampId.id])
      _.set(order, "receipts", receiptDetails)

      return enrichOrderWithPostOrderResponse(order, postOrderResponse, senderDetails)
    })

    trackPostOrderStatusEvent('individualpost', updatedOrders, response.ok)
    yield put(firstMileCreators.finishCreateOrder(updatedOrders))
  } else {
    const errorResponse: Response<ErrorData> = response
    yield put(showError(errorResponse.data.error.message))
    trackPostOrderStatusEvent('individualpost', orders, response.ok)
  }

  action.afterHandover && (yield call(action.afterHandover))
  yield put(showLoading(false))
}

export function * getPostOrderCreationResponse(country: String, response: any): Saga {
  let receiptDetails = {}
  const okResponse = response?.data?.data;
  const orderCreateAsyncRequestId = okResponse.order_create_async_request_id
  const receiptGenerationAsyncRequestId = okResponse.receipt_generation_async_request_id

  const postOrderCreationResponse = yield pollForPostOrderCreation(country, orderCreateAsyncRequestId)

  if (postOrderCreationResponse.status !== ASYNC_REQUEST_STATUS.FAILED) {
    receiptDetails = yield pollForPostOrderReceipts(country, receiptGenerationAsyncRequestId)
  }
  return { postOrderCreationResponse, receiptDetails }
} 

export function * pollForPostOrderCreation(country: String, orderCreateAsyncRequestId: String) : Saga {

  let pollCount = 0
  while (pollCount < MAX_POLL_COUNT) {
    const asyncFetchOrderResponse = yield call(getAsyncRequestApi, country, orderCreateAsyncRequestId)

    const asyncFetchOrderResponseError = asyncFetchOrderResponse.data.error
    if (asyncFetchOrderResponseError) {
      yield put(showError(asyncFetchOrderResponseError.message))
      return
    }
    const asyncFetchOrderResponseData = asyncFetchOrderResponse?.data?.data
    switch (asyncFetchOrderResponseData.status) {
      case ASYNC_REQUEST_STATUS.SUCCEEDED: {
        const response = {
          status : ASYNC_REQUEST_STATUS.SUCCEEDED,
          data: yield pollForPostOrderCreationAsyncRequestSuccess(asyncFetchOrderResponseData?.subRequests)
        }
        return response
      }
      case ASYNC_REQUEST_STATUS.FAILED: {
        const response = {
          status : ASYNC_REQUEST_STATUS.FAILED,
          data: yield pollForPostOrderCreationAsyncRequestFailedStatus(asyncFetchOrderResponseData?.subRequests)
        }
        return response
      }
      case ASYNC_REQUEST_STATUS.PARTIALLY_SUCCEEDED: {
        let ordersArray = []

        const subRequests = asyncFetchOrderResponseData?.subRequests
        const failedOrders = subRequests.filter(subRequest => subRequest.status === ASYNC_SUB_REQUEST_STATUS.ERROR);
        const successOrders = subRequests.filter(subRequest => subRequest.status === ASYNC_SUB_REQUEST_STATUS.SUCCESS);

        ordersArray.push(yield pollForPostOrderCreationAsyncRequestSuccess(successOrders))
        ordersArray.push(yield pollForPostOrderCreationAsyncRequestFailedStatus(failedOrders))

        const response = {
          status : ASYNC_REQUEST_STATUS.PARTIALLY_SUCCEEDED,
          data: _.flatten(ordersArray)
        }
        return response
      }
      default: {
        yield delay(POLL_DELAY_IN_MS)
        pollCount += 1
      }
    }
  }
}

export function * pollForPostOrderReceipts(country: String, receiptAsyncRequestId: String) : Saga {

  let pollCount = 0
  while (pollCount < MAX_POLL_COUNT) {
    const asyncFetchReceiptResponse = yield call(getAsyncRequestApi, country, receiptAsyncRequestId)

    const asyncFetchReceiptResponseError = asyncFetchReceiptResponse.data.error
    if (asyncFetchReceiptResponseError) {
      yield put(showError(asyncFetchReceiptResponseError.message))
      return
    }
    const asyncFetchReceiptResponseData = asyncFetchReceiptResponse?.data?.data
    switch (asyncFetchReceiptResponseData.status) {
      case ASYNC_REQUEST_STATUS.SUCCEEDED: {
        return JSON.parse(asyncFetchReceiptResponseData?.responseMessage)
      }
      case ASYNC_REQUEST_STATUS.FAILED: {
        yield put(showError(asyncFetchReceiptResponseData?.responseMessage))
        pollCount += MAX_POLL_COUNT
        break
      }
      default: {
        yield delay(POLL_DELAY_IN_MS)
        pollCount += 1
      }
    }
  }
}

export function * pollForPostOrderCreationAsyncRequestSuccess(subRequests: String) {
  let okResponse = []
  subRequests.map(subRequest => okResponse.push(JSON.parse(subRequest.responseMessage)))
  return okResponse
}

export function * pollForPostOrderCreationAsyncRequestFailedStatus(subRequests: String) {
  let stampIdAndErrorsArray = []

  const stampIdAndErrorMessagePair = subRequests.map(subRequest => [JSON.parse(subRequest?.requestPayload)?.stamp_id, subRequest?.responseMessage]) 
  stampIdAndErrorMessagePair.map(stampIdAndError => { 
    const data = { 
      error: {
        stamp_id: stampIdAndError[0],
        message: stampIdAndError[1]
      }
    }
    stampIdAndErrorsArray.push(data)
  })
  return stampIdAndErrorsArray
}

export function * retryCreatePostOrder (action: any): Saga {
  const { updateParcel } = firstMileCreators
  const order: Order = action.parcel
  const index: number = action.index
  const isDraft: boolean = action.isDraft
  const isBulkOrderCreation = false
  const afterHandover: Function = action.afterHandover

  yield put(showLoading(true))
  const country: string = yield select(selectCountry())
  const dpInfo: DpInfo = yield select(selectDpInfo())
  const senderDetails: SenderDetails = yield select(makeSelectFirstMileSenderDetails())

  const orders: Array<[Order, SenderDetails]> = [[order, senderDetails]]
  const payload: PostOrderAsyncRequestPayload = constructPostOrderAsyncRequestPayload(orders, dpInfo, country, isDraft, isBulkOrderCreation)

  const response = yield call(createPostOrderAsyncApi, country, payload)

  if (response.ok && response?.data?.data) {
    const { postOrderCreationResponse, receiptDetails  } = yield getPostOrderCreationResponse(country, response)   
    const postOrderResponse: PostOrderResponse =
      _.find(postOrderCreationResponse?.data, ['data.reservation.stamp_id', order.stampId.id]) ||
      _.find(postOrderCreationResponse?.data, ['error.stamp_id', order.stampId.id])
    _.set(order, "receipts", receiptDetails)

    const trackingId: string = postOrderResponse.data ? postOrderResponse.data.reservation.barcode : ''
    const updatedOrder: Order = enrichOrderWithPostOrderResponse(order, postOrderResponse, senderDetails)

    yield put(updateParcel(updatedOrder, index))
    if (postOrderCreationResponse?.status === ASYNC_REQUEST_STATUS.FAILED) { 
      const errorMessage = (postOrderResponse && postOrderResponse.error)? postOrderResponse.error.message : 'There was an unexpected error'
      yield call(showNotification, errorMessage , NOTIFICATIONS.ERROR)
    } else {
      yield call(showNotification, `Order with Tracking ID ${trackingId} is created`, NOTIFICATIONS.SUCCESS)
    }
  } else {
    yield call(showNotification, 'There was an unexpected error', NOTIFICATIONS.ERROR)
  }
  afterHandover && (yield call(afterHandover))
  yield put(showLoading(false))
}

export function * getReceiptIdListByShippers ({ shipperIdList }: any): Saga {
  const newReceiptIds = yield all(shipperIdList.map(shipperId => getReceiptIdSaga()))
  const shipperReceipts = shipperIdList.reduce(
    (receiptIds, shipperId, index) => ({
      ...receiptIds,
      [shipperId]: newReceiptIds[index]
    }),
    {}
  )
  yield put(firstMileCreators.successGetReceiptId(shipperReceipts))
}

export function * getReceiptId (action: any): Saga {
  let data = yield call(getReceiptIdSaga)
  yield put(firstMileCreators.successGetReceiptId(data))
}

export function * getReceiptIdSaga (): Saga {
  const country = yield select(selectCountry())
  const getReceiptIdResponse = yield call(createReceiptIdApi, country)
  const { data, error } = getReceiptIdResponse.data

  if (getReceiptIdResponse.ok) return data
  yield put(showError(getErrorMessage(getReceiptIdResponse, error)))
  yield put(firstMileCreators.failGetReceiptId())
}

export function * createPackOrder (action: any): Saga {
  yield put(showLoading(true))
  const { order, isManualPack, afterHandover } = action
  const { finishCreateOrder } = firstMileCreators
  const country = yield select(selectCountry())
  const dpInfo = yield select(selectDpInfo())
  const senderDetails = _.get(order, 'senderDetails')
  const parcels = _.get(order, 'parcels')

  yield call(getReceiptId)
  const receiptId = yield select(makeSelectFirstMileReceiptId())

  if (!receiptId) return

  const commonPayload = {
    receipt_id: receiptId,
    dp_id: dpInfo.id,
    ...getOCSenderPayload(senderDetails, dpInfo, country)
  }

  for (let i = 0; i < parcels.length; i++) {
    const payload: Object = isManualPack
      ? { ...commonPayload, ...getOCManualPackParcelPayload(parcels[i], country) }
      : { ...commonPayload }
    payload.tracking_id = _.get(parcels[i], 'trackingId')
    payload.image_url = _.get(parcels[i], 'recipientDetails.image.url')
    const createOrderResponse = yield call(createPackOrderApi, country, payload, isManualPack)
    const { error } = createOrderResponse.data
    if (createOrderResponse.ok) {
      _.set(parcels[i], 'oc.status', OC_STATUS.success)
      // call get receipts after the order create for pack succeeded
      const trackingId = createOrderResponse.data.data?.barcode
      if (!trackingId) {
        // this should not happen, but if it does, it should not block the OC call.
        continue
      }

      const receiptDetails = yield call(getOrderDetailsApi, country, trackingId)
      if (receiptDetails?.data) {
      }

    } else {
      _.set(parcels[i], 'oc.status', OC_STATUS.failed)
      _.set(parcels[i], 'oc.message', _.get(error, 'message', 'There was an unexpected error'))
    }
  }
  yield put(finishCreateOrder(parcels))
  yield put(showLoading(false))
  afterHandover && (yield call(afterHandover))
}

export function * retryCreatePackOrder (action: any): Saga {
  const { parcel, index, afterHandover } = action
  const { updateParcel } = firstMileCreators
  const country = yield select(selectCountry())
  const dpInfo = yield select(selectDpInfo())
  const senderDetails = yield select(makeSelectFirstMileSenderDetails())
  const receiptId = yield select(makeSelectFirstMileReceiptId())
  if (!receiptId) return
  const commonPayload = {
    receipt_id: receiptId,
    shipper_id: senderDetails.id,
    dp_id: dpInfo.id,
    ...getOCSenderPayload(senderDetails, dpInfo, country)
  }
  const payload: Object = { ...commonPayload }
  payload.tracking_id = _.get(parcel, 'trackingId')
  payload.image_url = _.get(parcel, 'recipientDetails.image.url')
  const createOrderResponse = yield call(createPackOrderApi, country, payload)
  const { error } = createOrderResponse.data
  if (createOrderResponse.ok) {
    _.set(parcel, 'oc.status', OC_STATUS.success)
    yield call(showNotification, `Order with id ${payload.tracking_id} is created`, NOTIFICATIONS.SUCCESS)
  } else {
    const errorMessage = _.get(error, 'message', 'There was an unexpected error')
    _.set(parcel, 'oc.status', OC_STATUS.failed)
    _.set(parcel, 'oc.message', errorMessage)
    yield call(showNotification, errorMessage, NOTIFICATIONS.ERROR)
  }
  yield put(updateParcel(parcel, index))
  afterHandover && (yield call(afterHandover))
}

export function * setDefaultShipper (action: any): Saga {
  const { id } = action
  const { selectShipper, successSetDefaultShipper, failSetDefaultShipper } = firstMileCreators
  const country = yield select(selectCountry())
  yield put(showLoading(true))
  const setDefaultResponse = yield call(dpApi.setDefaultShipper, country, id)
  const { data, error } = setDefaultResponse.data
  if (setDefaultResponse.ok) {
    yield put(successSetDefaultShipper(id))
    yield put(selectShipper(data))
  } else {
    const errorMessage = _.get(error, 'message')
    errorMessage && (yield put(showError(errorMessage)))
    yield put(failSetDefaultShipper())
  }
  yield put(showLoading(false))
}

export function * generateReceipt (action: any): Saga {
  const { params, onSuccess } = action
  if (!action.attempt) {
    action.attempt = 1
  } else {
    action.attempt = 2
  }
  const country = yield select(selectCountry())
  const { successGenerateReceipt, failGenerateReceipt } = firstMileCreators
  yield put(showLoading(true))
  const generateReceiptResponse = yield call(dpApi.generateReceipt, country, params)
  const { error } = generateReceiptResponse.data
  if (generateReceiptResponse.ok) {
    yield put(successGenerateReceipt())
    _.isFunction(onSuccess) && (yield call(onSuccess, params))
  } else {
    if (action.attempt === 1 && _.get(error, 'code') === ERROR_CODES.BR_MISSING_INVOICED_AMOUNT_DETAILS) {
      yield call(generateReceipt, action)
    } else {
      const errorMessage = _.get(error, 'message')
      errorMessage && (yield put(showError(errorMessage)))
      yield put(failGenerateReceipt())
    }
  }
  yield put(showLoading(false))
}

export function * generateAwb (action: any): Saga {
  yield put(showLoading(true))
  const { tids, paperSize, onSuccess, onFailure } = action
  const country = yield select(selectCountry())
  const payload = {
    tracking_ids: tids,
    hide_shipper_details: 0,
    size: paperSize || commons.AWB_SIZE_A4
  }
  const generateAwbResponse = yield call(dpApi.generateAwb, country, payload)
  const responseData = generateAwbResponse.data
  if (generateAwbResponse.ok) {
    const blob = new Blob([responseData], { type: 'application/pdf' })
    openFile(blob, 'awb.pdf')
    _.isFunction(onSuccess) && (yield call(onSuccess))
  } else {
    const { error } = arraybufferToJSON(responseData)
    if (error && error.message) yield put(showError(error.message))
    _.isFunction(onFailure) && (yield call(onFailure))
  }

  yield put(showLoading(false))
}

export function * editOrder (action: any): Saga {
  yield put(showLoading(true))
  const { order, afterHandover, isQuickAddressFeatureEnabled } = action
  const country = yield select(selectCountry())
  const dpInfo = yield select(selectDpInfo())
  const senderDetails = _.get(order, 'senderDetails')
  const parcel = _.get(order, 'parcels.[0]')

  const payload = {
    order_id: _.get(order, 'orderId'),
    receipt_id: _.get(order, 'receiptId'),
    dp_id: _.get(dpInfo, 'id'),
    ...getOCSenderPayload(senderDetails, dpInfo, country),
    ...getOCPostParcelPayload(parcel, dpInfo, country)
  }

  // For Quick Address, use billing zone from given L1/L2/L3 addresses to fetch order prices instead of lat/lng
  if (isQuickAddressFeatureEnabled) {
    const billingZoneRequestPayload = [
      {
        l1_name: parcel?.location?.state,
        l2_name: parcel?.location?.city,
        l3_name: parcel?.location?.district
      }
    ]

    const billingZoneResponse = yield call(getBillingZoneApi, country, billingZoneRequestPayload)
    const billingZoneInformation = _.get(billingZoneResponse, 'data')[0]
    payload.to_billing_zone = _.get(billingZoneInformation, 'data.billing_zone')
    payload.to_l1_id = _.get(billingZoneInformation, 'data.l1_id')
    payload.to_l2_id = _.get(billingZoneInformation, 'data.l2_id')
    payload.to_l3_id = _.get(billingZoneInformation, 'data.l3_id')
    payload.to_billing_zone_metadata = _.get(billingZoneInformation, 'data.metadata')
  }

  const editOrderResponse = yield call(dpApi.updatePostOrder, country, payload)
  const { error } = editOrderResponse.data
  if (editOrderResponse.ok) {
    _.set(parcel, 'oc.status', OC_STATUS.success)
  } else {
    _.set(parcel, 'oc.status', OC_STATUS.failed)
    _.set(parcel, 'oc.message', _.get(error, 'message', 'There was an unexpected error.'))
  }

  yield put(firstMileCreators.finishCreateOrder([parcel]))
  yield put(showLoading(false))
  afterHandover && (yield call(afterHandover))
}

export function * triggerNotifications (action: any): Saga {
  yield put(showLoading(true))
  const { params } = action
  const { successTriggerNotifications, failTriggerNotifications } = firstMileCreators
  const country = yield select(selectCountry())
  const receiptId = _.isNumber(params) ? params : params.id
  const triggerNotificationsResponse = yield call(dpApi.triggerNotification, country, receiptId)
  if (triggerNotificationsResponse.ok) {
    yield put(successTriggerNotifications())
  } else {
    const { error } = triggerNotificationsResponse.data
    const errorMessage = _.get(error, 'message')
    errorMessage && (yield put(showError(errorMessage)))
    yield put(failTriggerNotifications())
  }
  yield put(showLoading(false))
}

export function * getReceipt ({ idList }: any): Saga {
  yield put(showLoading(true))
  const { successGetReceipt } = firstMileCreators
  const receiptLinks = yield all(idList.map(receiptId => call(getReceiptSaga, { id: receiptId })))

  if (receiptLinks.length > 1) {
    let receiptZip = new JSZip()
    receiptLinks.forEach((url, index) => {
      let receipt = fetch(url).then(res => res.blob())
      receiptZip.file(`receipt-${index}.pdf`, receipt)
    })
    receiptZip.generateAsync({ type: 'blob' }).then(content => saveAs(content, 'receipts.zip'))
  } else {
    const receipt = _.get(receiptLinks, '[0]')
    window.open(receipt, '_blank')
  }
  yield put(successGetReceipt())
  yield put(showLoading(false))
}

export function * getReceiptSaga (action: any): Saga {
  const { id } = action
  const { failGetReceipt } = firstMileCreators
  const country = yield select(selectCountry())
  const getReceiptResponse = yield call(dpApi.getReceipt, country, id)
  const { data, error } = getReceiptResponse.data
  if (getReceiptResponse.ok) return data
  const errorMessage = _.get(error, 'message')
  errorMessage && (yield put(showError(errorMessage)))
  yield put(failGetReceipt())
}

export function * getSingleReceipt (action: any): Saga {
  const { id, isPrepaid } = action
  // A6 receipts generation is asynchronous, hence there is a chance of getting no receipt id.
  if (id) {
    const country = yield select(selectCountry())
    const response = yield call(dpApi.getReceipt, country, id)
    const { data, error } = response.data
    if (response.ok) {
      window.open(data, '_blank')
      return
    }
    if (error.code === ERROR_CODES.NF_RECEIPT.toString()) {
      const link = yield call(regenerateReceipt, { id, isPrepaid })
      link && window.open(link, '_blank')
      return
    }
    const errorMessage = _.get(error, 'message')
    errorMessage && (yield put(showError(errorMessage)))
    return
  }
  yield put(showError(PROCESSING_A6_RECEIPT_ERROR_MESSAGE))
}

export function * regenerateReceipt (action: any): Saga {
  yield put(showLoading(true))
  const { id, isPrepaid } = action
  const country = yield select(selectCountry())
  const response = yield call(dpApi.generateReceipt, country, { id, isPrepaid })
  const { data, error } = response.data
  if (response.ok) {
    return data
  } else {
    const errorMessage = _.get(error, 'message')
    errorMessage && (yield put(showError(errorMessage)))
  }
  yield put(showLoading(false))
}

//Do not perform validation if optional stamp id is supported and given stamp id is empty
export function * validateStampId (action: any): Saga {
  const country = yield select(selectCountry())
  const { stampId, onSuccess, callback, isStampIdOptional } = action

  if (isStampIdOptional && stampId === EMPTY_STAMP_ID) {
    onSuccess && (yield call(onSuccess, stampId))
  } else {
    const stampIdResponse = yield call(dpApi.validateStampId, country, stampId)
    const { error } = stampIdResponse.data
    if (stampIdResponse.ok) {
      yield call(showNotification, stampId, NOTIFICATIONS.SUCCESS)
      onSuccess && (yield call(onSuccess, stampId))
    } else {
      yield call(showNotification, error.message, NOTIFICATIONS.ERROR)
    }
    callback && (yield call(callback, error))
  }
}

export function * getEditOrderDetails (action: any): Saga {
  const { trackingId } = action
  yield put(showLoading(true))
  const country = yield select(selectCountry())

  const orderDetailsResponse = yield call(getOrderDetailsApi, country, trackingId)
  const { data, error } = orderDetailsResponse.data
  if (orderDetailsResponse.ok) {
    const { requestGetLocations } = locationsCreators
    const { isQuickAddressFeatureEnabled } = action

    // For Quick Address, we need to fetch L2 and L3 addresses to populate locations dropdown menus
    if (isQuickAddressFeatureEnabled) {
      const l1Address = data?.toState ?? null
      const l2Address = data?.toCity ?? null
      yield put(requestGetLocations(isQuickAddressFeatureEnabled, l1Address, l2Address))
    }
    yield put(firstMileCreators.successGetEditOrderDetails(data, country))
  } else {
    yield put(firstMileCreators.failGetEditOrderDetails())
    yield put(showError(getErrorMessage(orderDetailsResponse, error)))
  }
  yield put(showLoading(false))
}

export function * regenerateOrderReceipts(action: any): Saga {
  const { params, onSuccess } = action
  yield put(showLoading(true))
  const country = yield select(selectCountry())
  const generateReceiptResponse = yield call(dpApi.regenerateOrderReceipts, country, params)
  if (generateReceiptResponse.ok) {
    _.isFunction(onSuccess) && (yield call(onSuccess, params))
  } else {
    const { error } = generateReceiptResponse.data
    const errorMessage = _.get(error, 'message')
    errorMessage && (yield put(showError(errorMessage)))
  }
  yield put(showLoading(false))
}

export default function * firstMileData (): Saga {
  yield all([
    takeLatest(firstMileTypes.REQUEST_GET_ORDER, getOrder),
    takeLatest(firstMileTypes.REQUEST_GET_ORDER_WITHOUT_STAMP_ID, getOrderWithoutStampId),
    takeLatest(firstMileTypes.REQUEST_GET_SHIPPERS, getShippers),
    takeLatest(firstMileTypes.REQUEST_CREATE_SHIPPER, createShipper),
    takeLatest(firstMileTypes.REQUEST_SET_DEFAULT_SHIPPER, setDefaultShipper),
    takeLatest(firstMileTypes.REQUEST_UPLOAD_IMAGE, uploadImage),
    takeLatest(firstMileTypes.REQUEST_GET_PRICE, getPrice),
    takeLatest(firstMileTypes.REQUEST_LODGE_IN, lodgeIn),
    takeLatest(firstMileTypes.REQUEST_CREATE_POST_ORDER, createPostOrder),
    takeLatest(firstMileTypes.REQUEST_CREATE_PACK_ORDER, createPackOrder),
    takeEvery(firstMileTypes.REQUEST_GENERATE_RECEIPT, generateReceipt),
    takeLatest(firstMileTypes.REQUEST_GENERATE_AWB, generateAwb),
    takeLatest(firstMileTypes.REQUEST_RETRY_CREATE_POST_ORDER, retryCreatePostOrder),
    takeLatest(firstMileTypes.REQUEST_RETRY_CREATE_PACK_ORDER, retryCreatePackOrder),
    takeLatest(firstMileTypes.REQUEST_EDIT_ORDER, editOrder),
    takeLatest(firstMileTypes.REQUEST_TRIGGER_NOTIFICATIONS, triggerNotifications),
    takeLatest(firstMileTypes.REQUEST_GET_RECEIPT, getReceipt),
    takeLatest(firstMileTypes.REQUEST_VALIDATE_STAMP_ID, validateStampId),
    takeLatest(firstMileTypes.REQUEST_GET_RECEIPT_ID, getReceiptId),
    takeLatest(firstMileTypes.REQUEST_GET_RECEIPT_ID_LIST_BY_SHIPPERS, getReceiptIdListByShippers),
    takeLatest(firstMileTypes.REQUEST_GET_SINGLE_RECEIPT, getSingleReceipt),
    takeLatest(firstMileTypes.REQUEST_GET_EDIT_ORDER_DETAILS, getEditOrderDetails),
    takeLatest(firstMileTypes.REQUEST_SHIPPER_SETUP_STATUS, getShipperSetupStatus),
    takeEvery(firstMileTypes.REQUEST_REGENERATE_ORDER_RECEIPTS, regenerateOrderReceipts)
  ])
}
