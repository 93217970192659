/**
 * @file contains Recipient Details Form. Used in POST and manual input PACK pages.
 */
import { COUNTRY_DATA_KEYS, POST_FORM_FIELDS, PACK_FORM_FIELDS, getCountryData, COUNTRIES } from '@app/utils/constants'
import { Input, Row, Select } from 'antd'
import {
  InputWrapper,
  RecipientDetailsWrapper,
  StyledSubOption
} from '@app/components/RecipientDetailsForm/styles'
import React, { Fragment } from 'react'
import { CustomButton } from '@app/components/CustomButton'
import { FORM_ITEM_TYPES } from '@app/components/FormItemWrapper/constants'
import { FormItemWrapper } from '@app/components/FormItemWrapper'
import { FormItem } from '@app/components/FormItem'
import { SelectItem } from '@app/components/SelectItem'
import { LocationFormItem } from '@app/components/LocationFormItem'
import { Props } from '@app/models/RecipientDetailsForm.model'
import commons from '@app/constants/commons'
import { T } from '@nv/rc/Components'
import { Title } from './styles'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import { isStagingOrder } from '@app/utils/OrderUtils'
import { mixpanelTrackClearedAllRecipientFields } from '@app/utils/mixpanelUtils'
const Option = Select.Option

const RecipientDetailsForm = (props: Props) => {
  const { intl,
    form,
    formFields,
    parcel,
    country,
    handleInputChange,
    isEdit,
    isEditOrderEnabled,
    handleSelectLocation,
    handleConsigneeInfoSearch,
    selectedConsigneeInfo,
    consigneesInfo,
    handleQuickAddressSearch,
    isQuickAddressFeatureEnabled,
    isLoadingQuickAddressSearch,
    loading,
    orders,
    isPost,
    isPack
  } = props

  const createConsigneeInfoDropdownMenu = (key, consigneeInfoList, onSelect, options = {}) => {
    const { required } = options
    const { form } = props
    if (!consigneeInfoList || !consigneeInfoList.length) return null
    const { getFieldDecorator } = form
    const consigneeDropDownFieldKey = key + Math.random()
    return (
      <FormItem key={consigneeDropDownFieldKey}>
        {getFieldDecorator(consigneeDropDownFieldKey, {
          rules: [
            {
              required,
              message: intl.formatMessage({ id: 'please_select_an_item' })
            }
          ]
        })(
          <SelectItem fullwidth placeholder={intl.formatMessage({ id: 'select_consignee_details' })}
            onSelect={onSelect}
            data-testid='field-recipient-details'>
            {consigneeInfoList.map((item, index) => (
              <Option key={index} value={item.id} data-testid={`recipient-address-${index}`}>
                {item.name}&nbsp;&nbsp;&nbsp;{item.email}<br />
                <StyledSubOption>{item.address_1}, {item.postcode}, {item.state}, {item.city}, {item.locality}</StyledSubOption>
              </Option>
            ))}
          </SelectItem>
        )}
      </FormItem>
    )
  }

  const onSelectConsignee = (consigneeInfoId) => {
    const { handleConsigneeInfoSelected, consigneesInfo } = props
    const consignee = _.find(consigneesInfo, c => consigneeInfoId === c.id)
    handleConsigneeInfoSelected(consignee)
  }

  const renderConsigneesInfoDropDown = () => {
    const { consigneesInfo } = props
    const options = { translate: false, required: true, disabled: false }
    return createConsigneeInfoDropdownMenu(
      POST_FORM_FIELDS.consigneeInfo,
      consigneesInfo,
      onSelectConsignee,
      options
    )
  }

  const onClearConsigneeInfoClick = () => {
    const { handleClearConsigneeInfo } = props
    handleClearConsigneeInfo()
    mixpanelTrackClearedAllRecipientFields()
  }

  const getQuickAddressSearchPromptKey = (country) => {
    switch (country) {
      case COUNTRIES.VN:
        return intl.formatMessage({ id: 'quick_address_search_prompt_l1_province_l2_district_l3_ward'})
      case COUNTRIES.MY:
        return intl.formatMessage({ id: 'quick_address_search_prompt_l1_state_l2_city' })
      case COUNTRIES.TH:
        return intl.formatMessage({ id: 'quick_address_search_prompt_l1_province_l2_district_l3_subdistrict_th' })
      case COUNTRIES.ID:
        return intl.formatMessage({ id: 'quick_address_search_prompt_l1_province_l2_city_l3_subdistrict' })
      default:
        return ''
    }
  }

  return (
    <Fragment>
      <Title>
        <T id='recipient_details' />
      </Title>

      {isPost // Mobile search only enabled for POST orders
        ? <Row>
          <InputWrapper>
            <FormItemWrapper
              type={FORM_ITEM_TYPES.MOBILE_SEARCH}
              required
              country={country}
              dataTestId={'field-recipient-mobile-number'}
              fieldName={formFields.recipientContact}
              fieldId={formFields.recipientContact}
              label={intl.formatMessage({ id: 'mobile_no' })}
              width={300}
              form={form}
              initialValue={_.get(parcel, formFields.recipientContact, null)}
              onChange={e => handleInputChange(e.target.value, formFields.recipientContact)}
              handleSearch={handleConsigneeInfoSearch}
              loading={loading}
            />
          </InputWrapper>
        </Row>
        : <Row>
          <FormItemWrapper
            type={FORM_ITEM_TYPES.MOBILE}
            required
            country={country}
            dataTestId={formFields.recipientContact}
            fieldName={formFields.recipientContact}
            fieldId={formFields.recipientContact}
            label={intl.formatMessage({ id: 'mobile_no' })}
            width={300}
            form={form}
            initialValue={_.get(parcel, formFields.recipientContact, null)}
            onChange={e => handleInputChange(e.target.value, formFields.recipientContact)}
          />
        </Row>
      }

      {!selectedConsigneeInfo && consigneesInfo && consigneesInfo.length > 1
        ? <Row>
          {renderConsigneesInfoDropDown()}
        </Row>
        : null
      }

      <RecipientDetailsWrapper>
        <Row>
          <Input.Group compact>
            <FormItemWrapper
              style={{marginRight: '40px'}}
              form={form}
              fieldLabel={intl.formatMessage({ id: 'recipient_name' })}
              fieldId={formFields.recipientName}
              dataTestId={'field-recipient-name'}
              type={FORM_ITEM_TYPES.TEXT}
              initialValue={_.get(parcel, formFields.recipientName)}
              required
              placeholder={intl.formatMessage({ id: 'name' })}
              onChange={e => handleInputChange(e.target.value, formFields.recipientName)}
            />
            <FormItemWrapper
              form={form}
              fieldLabel={intl.formatMessage({ id: 'recipient_email_address' })}
              fieldId={formFields.recipientEmail}
              dataTestId={' field-recipient-email-address'}
              type={FORM_ITEM_TYPES.TEXT}
              initialValue={_.get(parcel, formFields.recipientEmail)}
              rules={[{
                type: 'email',
                message: intl.formatMessage({ id: 'enter_valid_email_address' })
              }]}
              placeholder={intl.formatMessage({ id: 'email_address' })}
              onChange={e => handleInputChange(e.target.value, formFields.recipientEmail)}
            />
          </Input.Group>
        </Row>

        <Row>
          <Title>
            <T id='recipients_address' />
          </Title>
        </Row>

        {/* Quick Address feature: Search bar for  user to quickly populate address fields from given address string */}
        {isQuickAddressFeatureEnabled &&
          <Row>
            <FormItemWrapper
              type={FORM_ITEM_TYPES.QUICK_ADDRESS_SEARCH}
              dataTestId={'field-recipient-quick-address-search'}
              fieldName={formFields.quickAddressSearchInput}
              fieldLabel={formFields.quickAddressSearchInput}
              fieldId={formFields.quickAddressSearchInput}
              width={300}
              form={form}
              label={getQuickAddressSearchPromptKey(country)}
              placeholder={intl.formatMessage({ id: 'quick_address_search_input_placeholder' })}
              initialValue={null}
              onChange={e => handleInputChange(e.target.value, formFields.quickAddressSearchInput)}
              handleSearch={handleQuickAddressSearch}
              loading={isLoadingQuickAddressSearch}
              // Editing of POST orders allowed in ID/PH/MY but not TH, thus we should not be able to use quick address
              // search bar anyway
              disabled={isStagingOrder(orders) || (isEdit && !isEditOrderEnabled)}
            />
          </Row>
        }

        <Row>
          {isPack // PACK has special fields and initial values different from default
            ? <FormItemWrapper
              form={form}
              fieldLabel={intl.formatMessage({id: 'postal_code'})}
              fieldId={PACK_FORM_FIELDS.postalCode}
              dataTestId='field-postal-code'
              type={FORM_ITEM_TYPES.TEXT}
              initialValue={_.get(parcel, PACK_FORM_FIELDS.postalCode)}
              maxLength={commons.NUMBER_FIELD_MAX_LENGTH}
              rules={[{
                len: getCountryData(country, COUNTRY_DATA_KEYS.postcodeLength),
                message: intl.formatMessage({ id: 'enter_valid_post_code' })
              }]}
              required
              placeholder={intl.formatMessage({id: 'enter_postal_code' })}
              onChange={e => handleInputChange(e.target.value, PACK_FORM_FIELDS.postalCode)}
            />
            : <FormItemWrapper
              form={form}
              fieldLabel={intl.formatMessage({id: 'post_code'})}
              maxLength={commons.NUMBER_FIELD_MAX_LENGTH}
              fieldId={formFields.recipientPostCode}
              dataTestId='field-postal-code'
              type={FORM_ITEM_TYPES.TEXT}
              initialValue={_.get(parcel, formFields.recipientPostCode)}
              rules={[{
                len: getCountryData(country, COUNTRY_DATA_KEYS.postcodeLength),
                message: intl.formatMessage({ id: 'enter_valid_post_code' })
              }]}
              required
              placeholder={intl.formatMessage({id: 'post_code'})}
              onChange={e => handleInputChange(e.target.value, formFields.recipientPostCode)}
            />
          }
        </Row>

        <Row>
          <Input.Group compact>
            <LocationFormItem
              form={form}
              parcel={parcel}
              shouldDisableLocationsDropdownMenu={isStagingOrder(orders) || (isEdit && !isEditOrderEnabled)} // Editing of POST orders allowed in ID/PH/MY but not TH
              formFields={formFields}
              locationNames={isPack
                ? ['province', 'city', 'district'] // PACK has special location names
                : ['state', 'city', 'district']}
              onSelectLocation={handleSelectLocation}
              isQuickAddressFeatureEnabled={isQuickAddressFeatureEnabled}
            />
          </Input.Group>
        </Row>

        <Row>
          <FormItemWrapper
            form={form}
            fieldLabel={intl.formatMessage({ id: 'street_name' })}
            fieldId={formFields.recipientAddress}
            dataTestId='field-recipient-address'
            type={FORM_ITEM_TYPES.TEXT}
            initialValue={_.get(parcel, formFields.recipientAddress)}
            required
            placeholder={intl.formatMessage({ id: 'street_name_input_placeholder' })}
            onChange={e => handleInputChange(e.target.value, formFields.recipientAddress)}
            width={700}
          />
        </Row>
      </RecipientDetailsWrapper>
      <Row>
        <CustomButton
          fullWidth
          dataTestId='clear-consignee-info'
          type='tertiary'
          onClick={e => onClearConsigneeInfoClick()}
          textId='clear_consignee_info'
          style={{ whiteSpace: 'normal', height: 'auto', minHeight: '2.25rem' }}
        />
      </Row>
    </Fragment>
  )
}

RecipientDetailsForm.defaultProps = {
  parcel: {},
  country: 'sg'
}

const RecipientDetailsForm_ = injectIntl(RecipientDetailsForm)
export { RecipientDetailsForm_ as RecipientDetailsForm }
