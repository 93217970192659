// @flow

const ENVIRONMENT = {
  DEV: 'dev',
  QA: 'qa',
  PROD: 'prod'
}

// pagination
const MAX_SEARCH_RESULT_SIZE = 10000
const PAGINATION_PAGE_SIZE = 50

// height
const ACTION_WRAPPER_HEIGHT = 80
const ACTION_WRAPPER_HEIGHT_MOBILE = 64
const HEADER_HEIGHT = 60
const TITLE_HEIGHT = 52
const TITLE_HEIGHT_MOBILE = 32
const DRAWER_HEIGHT = 300

// width
const MAX_MOBILE_WIDTH = 1224
const MIN_DESKTOP_WIDTH = 1224
const SIDEBAR_COLLAPSED_WIDTH = 0
const SIDEBAR_WIDTH = 250
const MIN_SIDEBAR_WIDTH = 60

// weight
const WEIGHT_PRECISION = 1
const MIN_WEIGHT = 0.1

// time interval
const FIVE_MINUTES = 300000
const ONCE_SECOND = 1000

// awb
const AWB_SIZE_A4 = 'A4'
const AWB_SIZE_A6 = 'A6'
const AWB_SIZE_PAPERANG = 'Paperang'

// receipt size
const RECEIPT_SIZE_A4 = "A4"
const RECEIPT_SIZE_A6 = "A6"

// fields length
const TEXT_FIELD_MAX_LENGTH = "255"
const PHONE_NUMBER_FIELD_MAX_LENGTH = "30"
const STAMP_ID_FIELD_MAX_LENGTH = "30"
const NUMBER_FIELD_MAX_LENGTH = "10"

export default {
  ACTION_WRAPPER_HEIGHT,
  ACTION_WRAPPER_HEIGHT_MOBILE,
  AWB_SIZE_A4,
  AWB_SIZE_A6,
  AWB_SIZE_PAPERANG,
  ENVIRONMENT,
  HEADER_HEIGHT,
  MAX_MOBILE_WIDTH,
  MAX_SEARCH_RESULT_SIZE,
  MIN_DESKTOP_WIDTH,
  PAGINATION_PAGE_SIZE,
  RECEIPT_SIZE_A4,
  RECEIPT_SIZE_A6,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_WIDTH,
  MIN_SIDEBAR_WIDTH,
  TITLE_HEIGHT,
  TITLE_HEIGHT_MOBILE,
  FIVE_MINUTES,
  WEIGHT_PRECISION,
  MIN_WEIGHT,
  DRAWER_HEIGHT,
  ONCE_SECOND,
  TEXT_FIELD_MAX_LENGTH,
  PHONE_NUMBER_FIELD_MAX_LENGTH,
  STAMP_ID_FIELD_MAX_LENGTH,
  NUMBER_FIELD_MAX_LENGTH
}
